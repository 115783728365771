import React, { useState, useEffect, useContext } from "react";
import { AiFillEye, AiFillGithub } from "react-icons/ai";
import { motion } from "framer-motion";

import { AppWrap, MotionWrap } from "../../wrapper";
import { urlFor, client } from "../../client";
import { useTranslation } from "react-i18next";
import "./Work.scss";
import { LanguageContext } from "../../App";

const Work = () => {
  const [works, setWorks] = useState([]);
  const [filterWork, setFilterWork] = useState([]);
  const {language} = useContext(LanguageContext);
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });
  const { t } = useTranslation();

  useEffect(() => {
    const query = '*[_type == "works"]';

    client.fetch(query).then(data => {
      setWorks(data);
      setFilterWork(data);
    });

  }, [language]);

  return (
    <>
      <h2 className="head-text">
        {t('my')} <span>{t('works')}</span>
      </h2>

      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__work-portfolio"
      >
        {filterWork.map((work, index) => (
          <div className="app__work-item" key={index}>
            <div className="app__work-content">

              <h4 className="bold-text">
                {language === 'ru' ? work.titleRu : work.titleEn}
              </h4>
              <p className="content-text" style={{ margin: "10px 0" }}>{language === 'ru' ? work.dateRu : work.dateEn}</p>
              <p className="content-text" style={{ margin: "20px 0" }}>
                {language === 'ru' ? work.descriptionRu : work.descriptionEn}
              </p>
              <h5 className="" style={{ margin: "10px 0" }}>{t('stack')}:</h5>
              <p className="stack">{work.stack}</p>

              <div className="links-wrapper">
                <a target="_blank" type="button" className="button" href={work.projectLink} style={{ marginRight: '20px' }}>{t(work.buttonText)}</a>
                {work.codeLink && <a target="_blank" type="button" className="button" href={work.codeLink}>Git Repo</a>}
              </div>

            </div> 

            <div className="app__work-img app__flex">
              <img src={urlFor(work.imgUrl)} alt={work.name} />
            </div>
          </div>
        ))}
      </motion.div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Work, "app__works"),
  "work",
  "app__primarybg"
);
