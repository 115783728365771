import React, {useState, useEffect, useContext} from 'react';
import {motion} from 'framer-motion';
import ReactTooltip from 'react-tooltip';

import {AppWrap, MotionWrap} from '../../wrapper';
import {urlFor, client} from '../../client';
import {useTranslation} from "react-i18next";
import './Skills.scss';
import {LanguageContext} from "../../App";

const Skills = () => {
  const {language} = useContext(LanguageContext);
  const [experiences, setExperiences] = useState([]);
  const [skills, setSkills] = useState([]);
  const {t} = useTranslation();
  
  useEffect(() => {
    const query = '*[_type == "experiences"] | order(_createdAt asc)';
    const skillsQuery = '*[_type == "skills"] | order(_createdAt asc)';
    
    client.fetch(query).then((data) => {
      setExperiences(data);
    });
    
    client.fetch(skillsQuery).then((data) => {
      setSkills(data);
    });
  }, []);
  
  return (
      <>
        <h2 className="head-text">
          <span>{t('skillsAndExperience')}</span>
        </h2>
        
        <div className="app__skills-container">
          <motion.div className="app__skills-list">
            {skills.map((skill, index) => (
                <motion.div
                    whileInView={{opacity: [0, 1]}}
                    transition={{duration: 0.5}}
                    className="app__skills-item app__flex"
                    key={index}
                >
                  <div
                      className="app__flex"
                      style={{backgroundColor: skill.bgColor}}
                  >
                    <img src={urlFor(skill.icon)} alt={skill.name}/>
                  </div>
                  <p className="skill-title">{skill.name}</p>
                </motion.div>
            ))}
          </motion.div>
          <div className="app__skills-exp">
            {experiences.map((experience, index) => (
                <motion.div
                    className="app__skills-exp-item"
                    key={index}
                >
                  <div className="app__skills-exp-year">
                    <p className="bold-text">{language === 'ru' ? experience.yearStartRu : experience.yearStartEn}&nbsp;-&nbsp;{language === 'ru' ? experience.yearEndRu : experience.yearEndEn}</p>
                  </div>
                  <motion.div className="app__skills-exp-works">
                    {experience.works.map((work, index) => (
                        <>
                          <motion.div
                              whileInView={{opacity: [0, 1]}}
                              transition={{duration: 0.5}}
                              className="app__skills-exp-work"
                              data-tip
                              data-for={work.nameEn}
                              key={index}
                          >
                            <h4 className="bold-text">{language === 'ru' ? work.nameRu : work.nameEn}</h4>
                            <div className="app__company">
                              <p className="p-text">{language === 'ru' ? work.companyRu : work.companyEn}</p>
                              <p className="p-text app__city">{language === 'ru' ? work.locationRu : work.locationEn}</p>
                            </div>
                            <div className="app__description"
                                 dangerouslySetInnerHTML={{__html: language === 'ru' ? work.descRu : work.descEn}}>
                            </div>
                          </motion.div>
                        </>
                    ))}
                  </motion.div>
                </motion.div>
            ))}
          </div>
        </div>
      </>
  );
};

export default AppWrap(
    MotionWrap(Skills, 'app__skills'),
    'skills',
    'app__whitebg',
);
