import React from 'react';
import { BsTwitter, BsTelegram } from 'react-icons/bs';
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa';

const SocialMedia = () => (
  <div className="app__social">
    <a target="_blank" href="https://t.me/ekaterina_kutovaya"
      rel="noreferrer">
      <BsTelegram />
    </a>
    <a target="_blank" href="https://www.linkedin.com/in/ekaterina-kutovaya"
      rel="noreferrer">
      <FaLinkedinIn />
    </a>
  </div>
);

export default SocialMedia;
