import React, { useContext, useEffect, useState } from "react";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { motion } from "framer-motion";

import "./Navbar.scss";

import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../App";


const Navbar = () => {
  const {language, changeLanguage} = useContext(LanguageContext);
  // const [activeLanguage, setActiveLanguage] = useState("ru");
  const [toggle, setToggle] = useState(false);
  const { t, i18n } = useTranslation();

  const handleMenu = () => {
    setToggle(true);
  }

  const handleLanguage = (lg) => {
    changeLanguage(lg);
    // setActiveLanguage(lg);
  };

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <h3>Ekaterina.</h3>
      </div>
      <ul className="app__navbar-links">
        {["home", "skills", "work", "contact"].map(item => (
          <li className="app__flex p-text" key={`link-${item}`}>
            <div />
            <a href={`#${item}`}>{t(`${item}`)}</a>
          </li>
        ))}
      </ul>

      <div>
        {["en", "ru"].map((lg, index) => (
          <button
            key={lg + index}
            onClick={() => handleLanguage(lg)}
            className={`language ${language === lg ? "lg-active" : ""}`}
          >
            {lg}
          </button>
        ))}
      </div>

      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={handleMenu}/>

        {toggle && (
          <motion.div
            initial={{ x: 300}}
            animate={{x: 0}}
            transition={{ duration: 0.5, ease: "easeOut" }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul>
              {["home", "skills", "work", "contact"].map(item => (
                <li key={item}>
                  <a href={`#${item}`} onClick={() => setToggle(false)}>
                    {t(`${item}`)}
                  </a>
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
