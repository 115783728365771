import React, {useState, createContext, useEffect} from 'react';
import { useTranslation } from "react-i18next";

import { About, Footer, Header, Skills, Testimonial, Work, Works } from './container';
import { Navbar } from './components';
import './App.scss';

export const LanguageContext = createContext('ru');

const App = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [])

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setLanguage(language);
  }

  return (
    <LanguageContext.Provider value={{language, changeLanguage}}>
      <div className="app">

        <Navbar />

        <Header />
        {/* <About /> */}
        {/* <Works />*/}
        <Skills />
        <Work />

        {/* <Testimonial /> */}
        <Footer />
      </div>
    </LanguageContext.Provider>
    
  )
}

export default App;
