import React, { useEffect } from 'react';
import { motion } from 'framer-motion';

import { useTranslation } from "react-i18next";
import { useState } from 'react';

import { AppWrap } from '../../wrapper';
import { images } from '../../constants';
import {  client } from "../../client";
import './Header.scss';

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
};

const Header = () => {
  const { t } = useTranslation();
  const [file, setFile] = useState('');
  

  useEffect(() => {
    const query = `*[_type == "cv"] {
      title,
      "manuscriptURL": manuscript.asset->url
    }`;

    client.fetch(query).then(data => {
      setFile(data[0].manuscriptURL);
      
    });
    
  }, []);


  return (
    <div className="app__header app__flex">
      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
        className="app__header-info"
      >
        <div className="app__header-badge">
          <div className="badge-cmp app__flex">
            <span>👋</span>
            <div style={{ marginLeft: 20 }}>
              <p className="p-text text">{t("hello")}</p>
              <h1 className="head-text h-text">{t("my_name")}</h1>
            </div>
          </div>

          <div className="tag-cmp app__flex">
            <p className="p-text">{t("about_me")}</p>
          </div>

          <div className="cv-badge app__flex">
            <a type="button" className="cv-button"
              rel="noopener noreferrer" download="Ekaterina_Kutovaya_Frontend_Dev_CV.pdf"
              target="_blank"
              href={`${file}?dl=<Ekaterina_Kutovaya_Frontend_Dev_CV.pdf>`}
            >
              {t("cv")}
            </a>
          </div>

        </div>
      </motion.div>

      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__header-img"
      >
        <img src={images.profile2} alt="" />

        <motion.img
          whileInView={{ scale: [0, 1] }}
          transition={{ duration: 1, ease: "easeInOut" }}
          src={images.circle}
          alt=""
          className="overlay_circle"
        />
      </motion.div>

      <motion.div
        variants={scaleVariants}
        whileInView={scaleVariants.whileInView}
        className="app__header-circles"
      >
        {[images.redux, images.react, images.sass].map((circle, index) => (
          <div className="circle-cmp app__flex" key={`circle-${index}`}>
            <img src={circle} alt="" />
          </div>
        ))}
      </motion.div>
    </div>
  );
}


export default AppWrap(Header, 'home');
